(<template>
  <div>
    <Error :errorCode="errorCode" :msg="msg" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    Error: () => import(/* webpackPrefetch: true */ "./common/Error"),
  },
  data: () => ({
    errorCode: "401",
    msg:
      "Your are not authorised to access this application, Please contact admin",
  }),
  created() {
    this.validateUser();
  },
  watch: {
    user() {
      this.validateUser();
    },
  },
  computed: {
    ...mapGetters({
      user: "GET_USER_ROLE",
    }),
  },
  methods: {
    validateUser() {
      if (this.user.isUserAccess) {
        this.$router.push({ name: "home" }).catch(() => {});
      }
    },
  },
};
</script>
)